window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('summernote');
    require('admin-lte');
    require('sweetalert');
    require('select2');
    window.moment = require('moment');
    window.Chart = require('chart.js');
    window.html2canvas = require('html2canvas');
    require("flatpickr");
    require("jquery-file-upload");
    require("daterangepicker");
    require('../../public/js/autoNumeric.js');
    require('../../public/plugin/orgchart/jquery.orgchart.js');
    require('../../public/plugin/sticky_notes/js/jquery.stickynote.js');
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import 'jquery-ui';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
// import 'summernote-bs4';
import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-colreorder';
import 'datatables.net-colreorder-bs4';

import Echo from 'laravel-echo';
import io from 'socket.io-client';

const wsSetting = process.env.MIX_WEBSOCKET_SETTING || 'off';
if(wsSetting == 'on') {
    const wsHost = process.env.MIX_WEBSOCKET_HOST || window.location.hostname;
    const wsPort = process.env.MIX_WEBSOCKET_PORT || "5037";
    const wsSecure = window.location.protocol === "https:";

    const wsProtocol = wsSecure ? "wss" : "ws";
    const wsUrl = `${wsProtocol}://${wsHost}/chatsocket/`;

    console.log("🟢 Connecting to WebSocket:", wsUrl);

    window.io = require("socket.io-client");

    window.Echo = new Echo({
        broadcaster: "socket.io",
        host: wsUrl,
        transports: ["websocket"],
        path: "/chatsocket/",
        withCredentials: true,
        auth: {
            headers: {
                "X-CSRF-TOKEN": document.querySelector("meta[name='csrf-token']")?.getAttribute("content")
            }
        }
    });

    // Enhanced WebSocket Debugging
    window.Echo.connector.socket.on('connect', () => {
        console.log("✅ WebSocket connected!");
        console.log("🔌 Socket ID:", window.Echo.connector.socket.id);
        console.log("📡 Socket State:", window.Echo.connector.socket.connected ? "Connected" : "Disconnected");
    });

    window.Echo.connector.socket.on('disconnect', (reason) => {
        console.log("❌ WebSocket disconnected! Reason:", reason);
    });

    window.Echo.connector.socket.on('error', (error) => {
        console.error("⚠️ WebSocket Error:", error);
    });

    window.Echo.connector.socket.on('reconnect_attempt', (attemptNumber) => {
        console.log("🔄 Attempting to reconnect... Attempt #" + attemptNumber);
    });

    window.Echo.connector.socket.on('reconnect', (attemptNumber) => {
        console.log("✅ Reconnected after " + attemptNumber + " attempts!");
    });

    // Get user ID from meta tag
    const userId = document.querySelector('meta[name="user-id"]')?.getAttribute('content');

    if (!userId) {
        console.warn("⚠️ User ID meta tag not found! Please add: <meta name='user-id' content='YOUR_USER_ID'>");
    } else {
        console.log(`🔔 User ID found: ${userId}`);
        window.Laravel = { userId: userId };

        // Fetch Redis prefix from the environment (Laravel may add a prefix)
        const REDIS_PREFIX = process.env.MIX_REDIS_PREFIX || ""; // Example: "ant_1_database_"
        const rawChannelName = `notifications.${userId}`;
        const redisChannelName = `${REDIS_PREFIX}private-notifications.${userId}`;

        console.log(`📡 Subscribing to Echo channel: ${rawChannelName}`);
        console.log(`🔄 Expected Redis channel: ${redisChannelName}`);

        // Subscribe to Laravel Echo private channel
        const channel = window.Echo.private(rawChannelName);

        console.log('🔄 Channel object created:', channel);

        // Debugging: Show Echo connection
        window.Echo.connector.socket.on('connect', () => {
            console.log('🔌 Socket connected');
        });

        window.Echo.connector.socket.on('message', (data) => {
            console.log('📨 Received message:', data);
        });

        window.Echo.connector.socket.on('notification', (data) => {
            console.log('🔔 Received notification:', data);
        });

        // Debug channel subscription
        channel.error((error) => {
            console.error("❌ Channel subscription error:", error);
        });

        // Listen for subscription success
        window.Echo.connector.socket.on('subscription-success', (data) => {
            console.log("✅ Subscription success:", data);
            console.log("🔌 Current socket rooms:", window.Echo.connector.socket.rooms);
        });

        channel.subscribed(() => {
            console.log(`✅ Successfully subscribed to ${rawChannelName}`);
            console.log('📡 Full channel name:', `private-${rawChannelName}`);

            // Send a test event to verify the connection
            window.Echo.connector.socket.emit('subscribe', { channel: `private-${rawChannelName}` });
        });

        // Listen for notifications with detailed logging
        channel.listen('.notification.created', (event) => {
            console.log("📨 Received .notification.created event:", event);
            handleNotification(event.notification);
        });

        // Debug: Log all incoming events
        window.Echo.connector.socket.on('notification', (data) => {
            console.log('🔔 Raw notification event received:', data);
            const receivedChannel = data.channel.replace(REDIS_PREFIX, "").replace('private-','');
            console.log('Raw Channel Name : ', rawChannelName);
            console.log('Received Channel Name : ', receivedChannel);
            if (data.event === '.notification.created' && receivedChannel === rawChannelName) {
                handleNotification(data.data);
            }
        });

        // Helper function to handle notifications
        function handleNotification(data) {
            try {
                console.log("📦 Processing notification:", data);

                // Update the notification count in the header
                const notificationBadge = document.querySelector('.badge-notification-count');
                if (notificationBadge) {
                    let currentCount = parseInt(notificationBadge.textContent) || 0;
                    notificationBadge.textContent = currentCount + 1;
                    console.log("✅ Updated notification count to:", currentCount + 1);
                }

                // Add notification to dropdown
                const notificationsContainer = document.querySelector('.notifications');
                if (notificationsContainer) {
                    notificationsContainer.insertAdjacentHTML('beforebegin', `
                        <a href="#" class="dropdown-item py-2 px-3 d-flex align-items-start gap-2">
                            <div class="flex-grow-1">
                                <div class="fw-semibold text-dark">${data.title || 'New Notification'}</div>
                                <div class="small text-muted">${data.message || 'You have a new notification.'}</div>
                            </div>
                            <div class="pt-1">
                                <i class="text-primary" title="Unread Notification"></i>
                            </div>
                        </a>
                    `);
                    console.log("✅ Added notification to dropdown");
                }

                // Create or get push notification element
                let pushNotification = document.querySelector('.push-notification');
                if (!pushNotification) {
                    pushNotification = document.createElement('div');
                    pushNotification.className = 'push-notification';
                    pushNotification.innerHTML = `
                        <div class="notification-content">
                            <div class="notification-title"></div>
                            <div class="notification-text"></div>
                        </div>
                        <button class="notification-close">&times;</button>
                    `;
                    document.body.appendChild(pushNotification);

                    // Add close button functionality
                    const closeButton = pushNotification.querySelector('.notification-close');
                    if (closeButton) {
                        closeButton.addEventListener('click', () => {
                            pushNotification.classList.remove('show');
                        });
                    }
                }

                // Update notification content
                const titleElement = pushNotification.querySelector('.notification-title');
                const messageElement = pushNotification.querySelector('.notification-text');

                if (titleElement && messageElement) {
                    titleElement.textContent = data.title || 'New Notification';
                    messageElement.innerHTML = data.message || 'You have a new notification.';
                }

                // Add styles if not already present
                if (!document.getElementById('notification-styles')) {
                    const style = document.createElement('style');
                    style.id = 'notification-styles';
                    style.textContent = `
                        .push-notification {
                            position: fixed;
                            top: 20px;
                            right: -500px;
                            width: 320px;
                            min-height: 45px;
                            max-height: fit-content;
                            background: white;
                            border-radius: 4px;
                            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
                            padding: 6px 12px;
                            transition: right 0.3s ease-in-out;
                            z-index: 9999;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            border-left: 4px solid #007bff;
                        }
                        .push-notification.show {
                            right: 20px;
                        }
                        .notification-content {
                            flex-grow: 1;
                            padding-right: 12px;
                            max-width: calc(100% - 24px);
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                        }
                        .notification-title {
                            font-size: 13px;
                            font-weight: 600;
                            color: #333;
                            margin: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 1.2;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .notification-text {
                            font-size: 11px;
                            color: #666;
                            line-height: 1.2;
                            margin: 2px 0 0 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        .notification-close {
                            background: none;
                            border: none;
                            font-size: 16px;
                            color: #999;
                            cursor: pointer;
                            padding: 0 3px;
                            line-height: 1;
                            align-self: flex-start;
                            margin: -2px -2px 0 0;
                        }
                        .notification-close:hover {
                            color: #666;
                        }
                        .dropdown-item {
                            padding: 6px 12px;
                            border-bottom: 1px solid #eee;
                            min-height: 45px;
                            display: flex;
                            align-items: flex-start;
                        }
                        .dropdown-item:last-child {
                            border-bottom: none;
                        }
                        .dropdown-item strong {
                            display: block;
                            font-size: 13px;
                            margin-bottom: 2px;
                            line-height: 1.2;
                        }
                        .dropdown-item p {
                            margin: 0;
                            font-size: 11px;
                            color: #666;
                            line-height: 1.2;
                        }
                        .dropdown-item i {
                            font-size: 14px;
                            margin-left: 8px;
                            color: #007bff;
                        }
                    `;
                    document.head.appendChild(style);
                }

                // Show the notification
                pushNotification.classList.add('show');

                // Hide after 10 seconds
                setTimeout(() => {
                    pushNotification.classList.remove('show');
                }, 10000);

                console.log("✅ Push notification displayed");
            } catch (error) {
                console.error("❌ Error handling notification:", error);
            }
        }
    }

    // Add CSS animations
    const style = document.createElement('style');
    style.textContent = `
        @keyframes slideIn {
            from { transform: translateX(100%); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
        }
        @keyframes slideOut {
            from { transform: translateX(0); opacity: 1; }
            to { transform: translateX(100%); opacity: 0; }
        }
    `;
    document.head.appendChild(style);
}